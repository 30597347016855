.contianer-card {
    margin: 20px;
    width: 250px;
    height: 300px;
    border-radius: 5px;
    background-size: 260px 300px;
    box-shadow: 3px 5px 5px rgba(3, 32, 51, .8);
    margin-bottom: 40px;
    position: relative;
}

.section-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .4);
    opacity: 0;
    transition: .5s;
    text-align: center;
    margin-top: 40px;

}

.section-card p {
    color: rgb(255, 255, 255);
    margin-top: 40px;
}

.contianer-card:hover .section-card {
    opacity: 1;
    border-radius: 5px;
    background: rgba(0, 0, 0, .4);
    /* background-color: #b0634079; */
}

h4 {
    color: white;
    text-align: center;
    background: rgba(0, 0, 0, .6);
    margin: 0;
    padding: 3px 0;
    border-radius: 5px 5px 0 0;
    font-size: 18px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    padding-bottom: 5px;
    z-index: 999;
}

/* .section-card h4{
    background: rgba(0, 0, 0, .4);
} */
.strongCard {
    cursor: pointer;
    color: #0d6efd;
}

.strongCard:hover {
    cursor: pointer;
    color: #0c51b9;
}

.imageCard {
    object-fit: contain;
    width: 300px;
    height: 400px;
}

.contianImageCards {
    width: 300px;
    height: 400px;
    margin: 0 10px;
}
.imgCardExpo{
    object-fit:contain;
    width:300px;
    height:400px;
}
.containImageCards{
    margin: 20px;
    padding: 10px;
    text-align: center;
    background-color: #ffffffcb;
    border-radius:5px ;
}
.containImageCards div button{
    margin-top: 10px;
    width: 200px;
    padding: 10px;
    display: flex;
    justify-content: center;
}
.containDataCards{
    justify-content:center;
    margin-left:20px;
    align-items:flex-start;
}
.containButtonCards{
    align-items: center;
}
.cardExpo{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-bottom:10px;
    border-radius: 5px;
    background-color: rgba(87, 87, 87, 0.466);
}

@media (max-width: 650px) {
    .imageCard {
        object-fit: contain;
        width: 150px;
        height: 150px;
    }
    .imgCardExpo{
        object-fit:contain;
        width:200px;
        height:250px;
    }

}