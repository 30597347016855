.modal{
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1 !important;
}
.modal-contenido{
    background: #f0f0f0;
    border-radius: 10px;
    padding: 10px 20px;
    margin:25vh auto;
    position: relative;
    z-index: -1;

}
.modal-contenido .btn{
    display: block;
    border-radius: 5px;
    margin-bottom:32px ;
    z-index: -1;

}
