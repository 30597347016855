.divFormEvents{

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height:120vh;
        overflow-y: hidden;
}
.divFormEvents form{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

@media (max-width: 650px) {
    .divFormEvents{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height:120vh;
        overflow-y: hidden;
    }

}