.expo{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 50px;

}
.containerr{
    margin-top: 0;
    margin-bottom: 200px;
    text-align: center;
    color: #B06440;
    /* border: solid 2px black; */
}
.div {
    display: flex;
    margin-top: 50px;
    overflow: auto;
    padding-bottom: 100px;
    background-color: #B06440;
    border-radius: 10px;
    justify-content: space-around;
}
.exp{
    text-align: center;
    color: white;
}
.button-inscripciones{
    margin: 25px;
    padding: 20px;
    background-color: #B06440;
    color: white;
    border-radius: 5px;
}
.cont{
    /* scroll-snap-align: center; */
    justify-content: center;
    margin-top: 100px;
}
.iframe{
    justify-content: center;
    text-align: center;
    border: #B06440 solid 5px;
    width: 700px; 
    height: 600px;
}
  
.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    width: 80%;
}
  
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
    color: #aaa;
    cursor: pointer;
}
.responsive-image {
    width: 100%; 
    height: 100px;
  }


@media (max-width: 650px) {
    .div {
        width: 100vw;
        display:flex;
        flex-direction: column;
        margin-top: 0px;
        padding-bottom: 0px;
        border-radius: 0px;
        justify-content: space-between;
        align-items: center;
    }
    .iframe{
        justify-content: center;
        text-align: center;
        border: #B06440 solid 5px;
        width: 100%; 
        height: 100vh;
    }
      
}