.divFormNews {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .divFormNews h3 {
    font-size: 24px;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 5px;

  }
  
  .divFormNews form {
    width: 400px;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .divFormNews input,
  .divFormNews textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .divFormNews textarea {
    height: 120px;
  }
  
  .divFormNews button {
    background-color: #010408;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    justify-content: center;
  }
  
  .divFormNews button:hover {
    background-color: #B06440;
  }

  .divButton{
    display: flex;
    justify-content: center;
    
  }
  